.ui-dropdown {
    min-width: 5em;
    width: auto !important; //Here to override PrimeNG computed width which for some reason is undersized

    border: $inputBorder;

    .ui-dropdown-label{
        color: $textColor;
    }

    .ui-dropdown-trigger {
        .ui-dropdown-trigger-icon {
            color: $textColor;
        }
    }

    &:hover{
        .ui-dropdown-label {
            color: $textColor;
        }

        .ui-dropdown-trigger{
            .ui-dropdown-trigger-icon {
                transform: scale(1.1);
                transition: transform 0.1s ease-in-out;
            }
        }
    }
}

