.ui-overlaypanel {
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: 0;
    border: $overlayContentBorder;
    @include border-radius(0);

    .ui-overlaypanel-content {
        padding: $panelContentPadding;
    }

    .ui-overlaypanel-close {
        background-color: $overlayPanelCloseIconBgColor;
        color: $overlayPanelCloseIconColor;
        width: 1.538em;
        height: 1.538em;
        line-height: 1.538em;
        text-align: center;
        position: absolute;
        top: -1 * 1.538em / 2;
        right: -1 * 1.538em / 2;
        @include transition($panelHeaderIconTransition);

        .ui-overlaypanel-close-icon {
            line-height: inherit;
        }

        &:hover {
            background-color: $overlayPanelCloseIconHoverBgColor;
            color: $overlayPanelCloseIconHoverColor;
        }

        &:focus {
            @include focused();
        }
    }

    &:after {
        border-color: rgba($panelContentBgColor, 0);
        border-bottom-color: $panelContentBgColor;
    }

    &:before {
        border-color: rgba($overlayContentBorderColor, 0);
        border-bottom-color: $overlayContentBorderColor;
    }

    &.ui-overlaypanel-flipped {
        &:after {
            border-top-color: $panelContentBgColor;
        }

        &:before {
            border-top-color: $overlayContentBorderColor;
        }
    }
}
